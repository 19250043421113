import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Image } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';

function MyWork(){
    const navigate = useNavigate();

    return(
        <section id="work">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="workTitle">
                            <h1> My Work </h1>
                            <p> Take a moment to explore My Work, and discover how I can help </p>
                            <p> you achieve your design goals.</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-80">
                    <div className="col-md-6">
                            <a href="#" rel="noreferrer">
                                <div className="workCard">
                                    <div className="imgContainer">
                                        <Image className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1709539241/tournament1_mkumva.png" alt="PintroCard"/>
                                    </div>
                                    <div className="workDesc">
                                        <h4>Tournament Website</h4>
                                        <p className="mb-0"> Coming soon.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    <div className="col-md-6">
                        <Link to="/blog-detail">
                            <div className="workCard">
                                <div className="imgContainer">
                                    <Image transition={false} className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678939188/cardMock-min_qq1d1h.png" alt="PintroCard"/>
                                </div>
                                <div className="workDesc">
                                    <h4>Pintro Card Merchant</h4>
                                    <p className="mb-0">Payment Platform to handle school store transactions, and this is the Merchant Version </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <a href="https://school-indra.pintro.id/" target="_blank" rel="noreferrer">
                            <div className="workCard">
                                <div className="imgContainer">
                                    <Image className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678939471/schoolMock-min_tasplk.png" alt="PintroCard"/>
                                </div>
                                <div className="workDesc">
                                    <h4>Pintro School</h4>
                                    <p className="mb-0"> A virtual school that makes it easier for children to obtain quality education at a more affordable price.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <a href="https://www.behance.net/gallery/134127881/Misidok-App-%28Redesign%29" target="_blank" rel="noreferrer">
                            <div className="workCard">
                                <div className="imgContainer">
                                    <Image transition={false} className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678939710/misiBan_je63hf.png" alt="PintroCard"/>
                                </div>
                                <div className="workDesc">
                                    <h4>Misidok Mitra</h4>
                                    <p className="mb-0"> Misidok application for partners or healthcare professionals that helps them to obtain homecare patients </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    
                    <div className="col-md-6">
                        <a href="https://www.behance.net/gallery/133183103/Misidok-Website-%28Redesign-Misidokid%29" target="_blank" rel="noreferrer">
                            <div className="workCard">
                                <div className="imgContainer">
                                    <Image transition={false} className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678939811/misiHome_snhkv2.png" alt="PintroCard"/>
                                </div>
                                <div className="workDesc">
                                    <h4>Misidok Homepage</h4>
                                    <p className="mb-0"> A Homepage Website for Misidok that functions to book healthcare professionals for homecare treatment. </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-6">
                        <a href="/#">
                            <div className="workCard">
                                <div className="imgContainer">
                                    <Image transition={false} className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678941727/palopoMock-min_c5a0ju.png" alt="PintroCard"/>
                                </div>
                                <div className="workDesc">
                                    <h4>Al-Azhar Palopo Company Profile</h4>
                                    <p className="mb-0">A company profile website for Al-Azhar Kelapa Gading branch in Palopo, South Sulawesi. </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <a href="/#">
                            <div className="workCard">
                                <div className="imgContainer">
                                    <Image transition={false} className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678944683/nacccc-min_mnwefu.png" alt="PintroCard"/>
                                </div>
                                <div className="workDesc">
                                    <h4>North Aquatic Center</h4>
                                    <p className="mb-0"> A website for booking tickets for the aquatic pool, facilities for jiujitsu, and EMS for Al-Azhar Kelapa Gading school in Jakarta. </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="btnWorkContainer">
                     <a href="/#" className="btnWork"> See all my work </a>
                </div>
            </div>
        </section>
    )
}

export default MyWork;