import React, {useEffect} from "react";
import NavHeader from "../../component/Navbar";
import Footer from "../../component/Footer";
import { Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function BlogDetail (){
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0)
    },[pathname]);
    return (
        <>
        <Helmet>
            <title>Detail Blog</title>
        </Helmet>
        <NavHeader/>
        <div className="container">
            <div className="row mt-80">
                <div className="col-md-7">
                    <div className="portfolioTitleContainer">
                        <h1 className="folio">Designfolio</h1>
                        <h1>Pintro Card Merchant</h1>
                        <p>An application aimed at facilitating cashless payments for cafeteria owners in school.</p>
                    </div>
                </div>
                <div className="col-md-4 offset-1 col-role">
                    <div className="detailRoleContainer">
                        <div className="detailRole">
                            <p className="roleTitle">My Role</p>
                            <p> UI/UX Designer </p>
                        </div>
                        <div className="detailRole">
                            <p className="roleTitle">Time Spent</p>
                            <p> 1 Month </p>
                        </div>
                        <div className="detailRole">
                            <p className="roleTitle">Project Category</p>
                            <p> Finance </p>
                        </div>
                        <div className="detailRole">
                            <p className="roleTitle">Tools</p>
                            <p> <img className="mr-8" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678857108/figmaLogo_a0ause.svg" alt="Figma"/> Figma </p>
                        </div>
                        <div className="detailRole">
                            <p className="roleTitle">Project Status</p>
                            <p> Integration Process </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 p-0">
                <div className="bannerPortfolio">
                    <Image transition={false} className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678940467/cardPortfoBanner-min_lekfo2.png" alt="banner"/>
                 </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row ">
                <div className="col-md-8 offset-md-2 mt-64">
                    <div className="projectBackground">
                        <h3>Project Background</h3>
                        <p>In Indonesia, there are many pesantrens (Islamic boarding schools) that do not allow their students to bring mobile phones. From this, the company sees an opportunity to digitize transactions in pesantren cafeterias with the aim of increasing the company's transaction volume. Thus, the idea of the Pintro Card application was created. Currently, the potential client is Al-Hamidiyah school, with 2000 students.</p>
                    </div>
                    <div className="projectBackground mt-40">
                        <h3>Brief & Goals</h3>
                        <p>In version 1, the most important thing is that students can already make transactions using the PintroCard. Feature development will continue after there is traffic. The application development process is also expected to be completed as soon as possible for sales purposes.</p>
                    </div>
                    <div className="imgOverview">
                        <img className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678865380/imgoverview_m3fju2.png" alt="imgOverview"/>
                        <p>Mockup Screenshot</p>
                    </div>
                    <div className="separatorDot">
                        <p>...</p>
                    </div>
                    <div className="designProcess mt-64">
                        <h1> Design Process </h1>
                        <p>After understanding the brief and goals, I researched who the users are and what they can do with this application. After that, I created a user journey to identify customer pain points and find out what solutions we can provide.</p>
                    </div>
                    <div className="userJourney mt-56 mb-32">
                        <h5>User Journey Transaksi</h5>
                        <img className="w-100" src="../assets/img/userJourneyPic.png" alt="userJourney"/>
                    </div>
                    <div className="userJourneyDesc">
                        <p>From the above user journey, I tried to investigate and summarize the possible scenarios of issues that may arise while using the application :</p>
                        <ul className="journeyLi">
                            <li>What if the students purchase various types of snacks in large quantities, while our system still needs to manually input the prices and descriptions of the items?<br></br>...</li>
                            <li className="solusi">Solution : Adding a photo feature in addition to the input of prices and descriptions, so that if the purchased items are too numerous and varied, the seller can take a photo without having to write the description and price of each item.</li>
                        </ul>
                        <ul className="journeyLi mt-4">
                            <li>What if the card scan fails because the barcode is dirty or damaged and cannot be read?<br></br>...</li>
                            <li className="solusi">Solution: Adding a manual input feature in the application, so if the QR code is not readable, the buyer can enter the card number and the transaction can still be carried out.</li>
                        </ul>
                    </div>

                    <div className="userJourney mt-56">
                        <h5>Flowchart</h5>
                        <img className="w-100" src="../assets/img/FlowchartEnglish.png" alt="userJourney"/>
                    </div>

                    <div className="userJourney mt-56">
                        <h5>Information Architecture</h5>
                        <p>After understanding the flow of the application that will be created, I tried to develop Information Architecture that aims to display information with clear structure and hierarchy, so that users can easily obtain the information they need.</p>
                        <img className="w-100 mt-48" src="../assets/img/InformationAEnglish.png" alt="userJourney"/>
                        <p className="imgDescPortfo">Homepage Information Architecture</p>    
                    </div>

                    <div className="finalDesignContainer">
                        <h1> Final Result </h1>
                        <div className="imgContainer">
                            <img className="w-100" alt="Final" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678945980/loginRegister_snxzq5-min_vbp7hc.png"/>
                            <p className="imgDescPortfo"> Login & Register Mockup</p>    
                        </div>

                        <div className="imgContainer">
                            <img className="w-100" alt="Final" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678946317/scanStep_ovvdec-min_qp1cje.png"/>
                            <p className="imgDescPortfo">Payment Scan With QR and Manual Input</p>    
                        </div>

                        <div className="imgContainer">
                            <img className="w-100" alt="Final" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678946562/rekapPelimpahan_z2vao6-min_p83lh5.png"/>
                            <p className="imgDescPortfo">Rekap Transaksi & Pelimpahan Mockup</p>    
                        </div>

                        <div className="imgContainer">
                            <img className="w-100" alt="Final" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678946697/profilePage_ewoj6z-min_1_nqjodq.png"/>
                            <p className="imgDescPortfo">Profile Mockup</p>    
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p className="designSystemTitle"> Design System </p>
                    <div className="designSystemListContainer">
                        <div className="designSystemParent">
                            <div className="designSystemContainer">
                                <h4>Typography</h4>
                            </div>
                            <Image className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678874349/typo_qvp0ml.png" alt="typo"  />
                        </div>

                        <div className="designSystemParent">
                            <div className="designSystemContainer">
                                <h4>Colors</h4>
                            </div>
                            <Image className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678874702/colors_mblngl.png" alt="typo"  />
                        </div>

                        <div className="designSystemParent">
                            <div className="designSystemContainer">
                                <h4>Components</h4>
                            </div>
                            <Image className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678875294/othersss_opg8y8.png" alt="typo"  />
                        </div>
                        
                        <div className="designSystemParentTwo">
                            <div className="designSystemParent">
                                <div className="designSystemContainer">
                                    <h4>Iconography</h4>
                                </div>
                                <Image className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678876144/icono_sec4nl.png" alt="typo"  />
                            </div>
                            <div className="designSystemParent">
                                <div className="designSystemContainer">
                                    <h4>Illustration</h4>
                                </div>
                                <img className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678876223/illus_osuuqv.png" alt="typo"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid">
            <div className="row bg-lightYellow mt-80">
                <div className="col-md-8 offset-2 col-role">
                    <div className="summaryPortfo">
                        <h3> Summary </h3>
                        <p>Currently, the PintroCard project is entering the integration phase after going through the slicing process. For the development of this application, I plan to conduct direct research with users to gain a deeper understanding of their behavior, field conditions, and how users do their accounting/bookkeeping, with the hope that the design decisions taken are based on data.</p>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>
        </>
        
    )
}

export default BlogDetail;