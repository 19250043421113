import React from "react";
import { Image } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

function Blog(){
    return(
        <section id="blog">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <div className="blogTitle">
                            <h1> Blog</h1>
                            <p>Sharing stories, insights, and experiences from my perspective</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="allStoryBtn">
                            <h5> See all my stories</h5>
                            <Image transition={false} src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678844222/arrowFramer_q7sl1j.svg" alt="arror"/>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-4">
                        <a href="/#" className="blogHref">
                        <div className="blogContainer">
                            <div className="blogImg">
                                 <Image transition={false} className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678784133/frontendExpertise_kgvtvz.svg" alt="Frontend"/>
                            </div>
                            <div className="blogJudul">
                                <p>From Sketch to Screen: A Guide to the UI/UX Design Process👋 🔥</p>
                            </div>
                        </div>
                        </a>
                    </div>

                    <div className="col-md-4">
                        <a href="/#" className="blogHref">
                        <div className="blogContainer">
                            <div className="blogImg">
                                 <Image transition={false} className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678785421/uiuxExpertise_dqb6g1.svg" alt="Frontend"/>
                            </div>
                            <div className="blogJudul">
                                <p>Enhancing User Experience: Tips and Tricks for UI/UX Designers  🚀 💨</p>
                            </div>
                        </div>
                        </a>
                    </div>

                    <div className="col-md-4">
                        <a href="/#" className="blogHref">
                        <div className="blogContainer">
                            <div className="blogImg">
                                 <Image transition={false} className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678785564/contentDesignexpertise_tfpgca.svg" alt="Frontend"/>
                            </div>
                            <div className="blogJudul">
                                <p>The Art of UI/UX Design: Creating User-Centered Designs ☠️ ☠️</p>
                            </div>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Blog;