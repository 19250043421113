import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Image } from "react-bootstrap";
import Container from 'react-bootstrap/Container';


function Hero(){
    return(
        <section id="hero">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="nameDescParent">
                            <p className="nameHero">Bagas Kurniawan Majid</p>
                            <p className="descHero">I was a young man who fell in love with something beautiful, well-organized and patterned. Started my journey as a web designer 5 years ago, and now I am studying and working as a UI/UX Designer in a company in East Jakarta, Indonesia</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="ageLabel">
                            <label>Age</label>
                            <p> 23 Years Old </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="ageLabelJob">
                            <label>Current Position</label>
                            <p> UI/UX Designer </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="sosmedHero">
                            <div className="sosmedContainer">
                                <img src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678777146/envelope-solid_1_zmee23.svg" alt="email"/>
                            </div>
                            <div className="sosmedContainer">
                                <a href="https://www.linkedin.com/in/bagas-kurniawan-majid" target="_blank" rel="noreferrer">
                                <img src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678777562/linkedina_ky706e.svg" alt="linkedin"/>
                                </a>
                            </div>
                            <div className="sosmedContainer">
                             <a href="https://www.instagram.com/bagasroti/" target="_blank" rel="noreferrer">
                                <img src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678777661/instagrame_ouv9tc.svg" alt="instagram"/>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="scrollContainer">
                        <Image src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678779306/scrollto_yzl4tv.svg" alt="scroll"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

{/* <p> Scroll to explore</p>
<div className="btnScroll">
    <img src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678778069/arrow_down1_gbltxb.svg" alt="scroll"/>
</div> */}
export default Hero;