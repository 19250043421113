import React from "react";
import { Image } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

function Expertise (){
    return(
        <section id="expertise">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="expertiseParent"> 
                            <h1> My Expertise</h1>
                            <p> A UI/UX professional with a strong combination of design and development skills. Has a passion for learning new technologies and staying up-to-date with industry trends. </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="expertNumberParent">
                            <div className="expertNumber">
                                <h1> 23 </h1>
                                <p> Years Old </p>
                            </div>
                            <div className="expertNumber">
                                <h1> 4+ </h1>
                                <p> Years of Experience </p>
                            </div>
                            <div className="expertNumber">
                                <h1> 10+ </h1>
                                <p> Projects Done </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-4">
                        <div className="expertiseCard">
                            <div className="imgContainer">
                                <Image className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678784133/frontendExpertise_kgvtvz.svg" alt="Frontend"/>
                            </div>
                            <div className="expertiseDesc">
                                <h4> Frontend Design </h4>
                                <p>I design great user experiences with React, HTML, CSS, and JS.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="expertiseCard">
                            <div className="imgContainer">
                                <Image className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678785421/uiuxExpertise_dqb6g1.svg" alt="UI/UX Design"/>
                            </div>
                            <div className="expertiseDesc">
                                <h4> UI/UX Design </h4>
                                <p>I design exceptional UI/UX experiences with product and interaction design</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="expertiseCard">
                            <div className="imgContainer">
                                <Image className="w-100" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678785564/contentDesignexpertise_tfpgca.svg" alt="Content Design"/>
                            </div>
                            <div className="expertiseDesc">
                                <h4> Content Design </h4>
                                <p>I create content for social media, specializing in Instagram design.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Expertise;