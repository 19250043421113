import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer(){
    return(
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="footerContainer">
                        <div className="footerTitle">
                            <h1>Ready to make something 
                                great together?</h1>
                            <p>Email me at <span>bagaskuaci465@gmail.com</span></p>
                        </div>
                        <a href="https://api.whatsapp.com/send?phone=62895415500849" target="_blank" className="footerBtn" rel="noreferrer">
                            <img src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678845608/wa1_rm6vja.svg" alt="whatsapp"/>
                            <p>Whatsapp Me</p>
                        </a>
                    </div>
                    <div className="credit">
                        <p>Designed by Bagas Kurniawan. Made with React JS | ©2023</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;