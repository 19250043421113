import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, 
  Route, Routes, useLocation} from "react-router-dom";
import Home from './pages/Home';
import BlogDetail from './pages/Blog';
import { useEffect } from 'react';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path ="/blog-detail" element={<BlogDetail/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
