import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';


function NavHeader(){
  const navigate = useNavigate();

    return(
        <Navbar className={Navbar} expand="lg">
            <Container>
          <Navbar.Brand onClick={()=> navigate('/')}><img src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1678725799/logoku_pevhm9.svg" alt="logo"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto menuNavbar m-auto">
              <Nav.Link href="#hero">Profile</Nav.Link>
              <Nav.Link href="#work">Portfolio</Nav.Link>
              <Nav.Link href="#blog">Blog</Nav.Link>
              <Nav.Link href="#footer">Contact</Nav.Link>
            </Nav>
            <Nav>
                <Nav.Link href="https://api.whatsapp.com/send?phone=62895415500849" target="_blank" rel="noreferrer" className="btnTalk"> Let's Talk </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
        </Navbar>
    )
}

export default NavHeader;