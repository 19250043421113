import NavHeader from '../../component/Navbar'
import React, { useState, useEffect } from 'react';
import Hero from '../../component/Hero';
import Expertise from '../../component/Expertise';
import MyWork from '../../component/MyWork';
import Footer from '../../component/Footer';
import Blog from '../../component/Blog';
import './loading.css'
import { Helmet } from 'react-helmet';

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="container-loading" style={{ backgroundColor: '#181D2C' }}>
          <div className="loading-wrapper">
            <div className="shine"></div>
            <img className="loading-image" src="https://res.cloudinary.com/dezm8cnkw/image/upload/v1708419656/logowin_yrvyb2.png" alt="loading" />
            <div className="content-container">
            <div className="loading-text">Loading</div>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Bagas Kurniawan</title>
          </Helmet>
          <NavHeader />
          <Hero />
          <Expertise />
          <MyWork />
          <Blog />
          <Footer />
        </>
      )}
    </>
  );
}

export default Home;